@media (min-width: 1024px) {
  .ion-page {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }
}

.flag {
  height: 0.75em;
}

.no-text-decoration {
  text-decoration: none;
}

.app-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.app-title__left {
  align-items: center;
}

.app-title h2 {
  margin: 0px;
  flex-grow: 1;
}

.app-title__right {
  height: 50px;
}

.app-title .logo {
  font-size: 50px;
  width: 50px;
  height: 50px;
}

.logos img {
  max-height: 100px;
}

.related-list {
  background-color: var(--ion-color-light);
  border-top: 1px solid var(--ion-color-light-shade);
  border-bottom: 1px solid var(--ion-color-light-shade);
}

.subheading {
  font-size: 16px;
  color: var(--ion-color-medium);
  text-transform: uppercase;
  margin-bottom: -10px;
}

mark.current {
  background-color: orange;
}

.tab-bar {
  padding-top: 5px;
}

/* Classes to replace action-button */
.action-card {
  --border-radius: 5px;
  background-color: #fff;
  padding: 15px 22px 14px 17px;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.31);
  line-height: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 16px;
}

hr {
  opacity: 0.25;
  border-top: solid 1px #979797;
  margin: 0;
}

hr.divider,
.header-divider,
.list-divider {
  margin: 35px auto;
}

.chapter {
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.header-divider {
  margin-top: 10px;
}

.list-divider {
  margin-bottom: 0;
}

.contents-label {
  font-family: "Noto Serif";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: -0.09px;
  color: #747474;
}

.topic-item,
.case-item {
  background-color: #ffffff;
  padding: 10px 16px 16px 14px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.31);
}

.case-item {
  padding: 18px 13px 9px 14px;
}

.topic-item > div {
  display: flex;
}

.card-div {
  align-items: center;
}

.topic-item h3,
.case-item h3 {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  margin-bottom: 7px;
}

.topic-item p,
.case-item p {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #262626;
}

.related-guides {
  padding: 10px;
  display: flex;
  align-items: flex-start;
}

.related-guides span {
  font-family: "Noto Serif";
  margin-left: 10px;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #ffffff;
}


.full-width {
  width: 100%;
}


.hr-divider {
  margin-top: 20px;
  margin-bottom: 20px;
}
.language-switcher ion-item {
  --padding-start: 0;
  --border-style: none;
  --background: white;
  background-color: white;
  margin: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.31);
  border-radius: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.language-switcher ion-item img {
  margin-left: 10px;
  width: 28px;
  height: 28px;
}

@media (max-width: 425px) {
  .full-height-mobile {
    height: 100%;
  }

  .virtual-list-container.ion-content::part(scroll) {
    display: flex;
    flex-direction: column;
  }


  .virtual-list-container ion-list {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.toc-list {
  height: 100%;
}

ion-menu-toggle .toc-list {
  padding-top: var(--ion-safe-area-top, 0);
}
