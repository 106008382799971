.topic-content {
  font-family: Palatino, "Book Antiqua", "PT Serif", Georgia, serif;
  font-size: 16px;
  line-height: 1.35;
}

.topic-heading {
  background-color: var(--ion-color-light);
  padding-bottom: 0px !important;
  border-bottom: 1px solid var(--ion-color-light-shade);
}

.topic-heading .ion-margin-top {
  margin-top: 35px;
}

.topic-info {  
  background-color: var(--ion-color-light);
}

.provision-insert {
  border-bottom: 1px solid var(--ion-color-light-shade);
  padding-bottom: 0px !important;
  max-height: 50vh;
  overflow-y: scroll;
}
