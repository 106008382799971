.con-buttons {
  min-height: 52px;
  border-radius: 5px;  
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.31);
  --background: var(--ion-color-primary-contrast);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  --border-radius: 5px;
  padding: 17px 22px 12px 18px;
  font-family: 'Noto Serif';
  color: #262626;
}

.con-buttons > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.top-icon {
  margin-bottom: 10px;
}
