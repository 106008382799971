.case-content {
  font-family: Palatino, "Book Antiqua", "PT Serif", Georgia, serif;
  font-size: 16px;
  line-height: 1.35;
}

.case-title {
  background-color: #ffffff;
  margin: 0;
  padding: 29px 16px;
}
