ion-segment-button {
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: -0.09px;
  --color: #747474;
}

.search-title {
  display: flex;
  align-items: center;
}

.search-title h2 {
  font-size: 25px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.15px;
  color: #262626;
  margin-top: 10px;
  width: 80%;
  margin-bottom: 0;
}

.search-title .icon {
  margin-top: 1.03em
}

.search-bar .searchbar-input-container {
  --background: #ffffff;
  height: 52px;
  --border-radius: 5px;
  margin-bottom: 20px;
}